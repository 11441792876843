import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Layout from "../templates/Layout"
import Masonry from "react-masonry-css"
import Button from "../components/button/Button"

import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

import * as styles from "./gallery.module.css"


export const pageQuery = graphql`
    query Gallery {
        mdx(slug: {eq: "gallery"}) {
            body
            frontmatter {
              title
              summary
            }
            body
        },
        allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData (
                            transformOptions: {
                                cropFocus: ATTENTION
                            }
                        )
                    }
                }
            }
        }
    }
`


class Gallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = { toggle: false }
        this.toggle = this.toggle.bind(this)
        this.selectedImage = null
    }

    toggle() {
        this.setState(state => ({ toggle: !state.toggle }))
    }

    render() {
        const data = this.props.data.mdx
        const gallery = this.props.data.allFile.edges

        const breakpointColumnsObj = {
            default: 4,
            1100: 3,
            700: 2,
            500: 1
        }

        const { selectedImage, toggle } = this.state

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <h1>{data.frontmatter.title}</h1>
                <p>{data.frontmatter.summary}</p>
                <MDXRenderer>{data.body}</MDXRenderer>

                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className={styles.wrapper}
                    columnClassName={styles.column}
                >

                {gallery.map((photo, i) => (
                    <button
                        key={i++}
                        className={styles.button}
                        onClick={() =>
                            this.setState({ toggle: true, selectedImage: photo.node })
                        }
                    >
                        <GatsbyImage
                            image={getImage(photo.node)}
                            alt=""
                            className={styles.photo}
                        />
                    </button>
                ))}
                </Masonry>

                {toggle &&
                    <Dialog
                        onDismiss={this.toggle}
                        className={styles.dialog}
                    >
                        <div className={styles.content}>
                            <GatsbyImage
                                image={getImage(selectedImage)}
                                alt=""
                                className={styles.photoBig}
                            />
                            <Button
                                secondary
                                className={styles.close}
                                onClick={this.toggle}
                            >
                                Close
                            </Button>
                        </div>
                    </Dialog>
                }
            </Layout>
        )
    }
}
        
export default Gallery